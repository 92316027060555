<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('headers.group.title')" :description="$t('headers.group.description')"/>

    <!-- Info -->
    <div id="info">{{ $t('group.description') }}</div>

    <!-- Invitations -->
    <div v-if="invitations.length > 0 && myGroup === ''">
      <div v-for="invitation in invitations" :key="invitation.group" class="invitation">
        <div v-if="isInvitationInProgress(invitation)">{{ $t('group.game-in-progress') }}:</div>
        <div v-else>{{ invitation.name }} {{ $t('group.invites-you-to-game') }}:</div>
        <div class="invitation-card d-flex flex-row flex-wrap justify-start align-center pa-1">
          <v-icon size="60" color="primary" class="flex-shrink-1 mx-3">{{ '$i-' + invitation.event }}</v-icon>
          <div class="invitation-card-title flex-grow-1">{{ $t('events.' + invitation.event) }}</div>
          <div class="d-flex flex-row flex-nowrap justify-center align-center ma-2">
            <div class="invitation-card-info-1 flex-shrink-1">
              {{ $t('group.memo-time') }}:<br>{{ $t('group.recall-time') }}:<br>{{ $t('group.number-of-elements') }}:
            </div>
            <div class="invitation-card-info-2 flex-shrink-1">
              {{ parseTime(invitation.memo) }}<br>{{ parseTime(invitation.recall) }}<br>{{ invitation.number }}
            </div>
          </div>
          <v-btn color="primary" class="flex-shrink-1 ma-2" width="150"
                 :disabled="loading" @click="join(invitation.group)">
            {{ $t('group.join') }}
          </v-btn>
        </div>
        <div v-if="!isInvitationInProgress(invitation) && invitation.event === 'numbers'" style="max-width: 355px">
          <v-slider dense v-model="highlights[invitation.group]" :disabled="loading"
                    :label="$t('common.highlight') + ': ' + labels['numbers'][highlights[invitation.group]]"
                    :tick-labels="labels['numbers']" :min="0" :max="4" style="width: 300px" class="mx-3"/>
        </div>
        <div v-if="!isInvitationInProgress(invitation) && invitation.event === 'words'" style="max-width: 355px">
          <v-slider dense v-model="highlights[invitation.group]" :disabled="loading"
                    :label="$t('common.highlight') + ': ' + labels['words'][highlights[invitation.group]]"
                    :tick-labels="labels['words']" :min="0" :max="3" style="width: 300px" class="mx-3"/>
        </div>
      </div>
    </div>

    <!-- No invitations -->
    <div v-else-if="myGroup === ''" class="d-flex flex-column flex-nowrap justify-center align-center"
         style="padding: 16px; text-align: center">
      <v-icon size="60" color="primary" class="mb-3">mdi-account-group</v-icon>
      <div>{{ $t('group.no-invitations') }}</div>
    </div>

    <!-- Group -->
    <div v-else class="d-flex flex-column flex-nowrap justify-center align-center"
         style="padding: 16px; text-align: center">
      <v-progress-circular indeterminate color="primary" size="60" width="6" class="mb-3"/>
      <div>{{ $t('group.waiting-for-start') }}</div>
    </div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderBar';

export default {
  name: 'MainGroup',
  components: {Header},
  data: () => ({
    invitations: [],
    myGroup: '',
    loading: false,
    listener: null,
    highlights: {},
    labels: {
      'numbers': [1, 2, 3, 4, 6],
      'words': [1, 2, 3, 4]
    }
  }),
  mounted() {
    this.listener = new EventSource(this.$url + '/api/listen-group-member', {withCredentials: true});
    this.listener.onmessage = (event) => {
      let data = JSON.parse(event.data);
      this.invitations = data.invitations;
      let group = this.invitations.find((invitation) => invitation.online);
      this.myGroup = group == null ? '' : group.group;

      if (group == null) this.invitations = this.invitations.filter((invitation) => invitation.started === '0' || this.isInvitationInProgress(invitation));



      if (group != null && group.started !== '0') {
        this.listener.close();
        this.$api.getGame(group.gid).then(() => {
          this.$store.commit('setLocation', '/group');
          this.$router.replace('/play/training/' + group.event);
        }).catch(() => {
          this.$store.commit('showSnackbar', this.$t('common.error'));
        })
      }
    };
  },
  destroyed() {
    if (this.listener !== null) this.listener.close();
  },
  methods: {
    isInvitationInProgress(invitation) {
      let isGidNotNull = invitation.gid != null;
      let isStarted = invitation.started !== '0';
      let isNotExpired = parseInt(invitation.started) + 1000 * (invitation.memo + 10 + invitation.recall) > new Date().getTime();
      return isGidNotNull && isStarted && isNotExpired;
    },
    join(group) {
      let invitation = this.invitations.find((invitation) => invitation.group === group);
      if (invitation === undefined) return;
      this.loading = true;
      let highlight = ['numbers', 'words'].includes(invitation.event) ? this.labels[invitation.event][this.highlights[invitation.group]] : 1;
      if (isNaN(highlight)) highlight = 1;
      this.$api.joinGroup(group, highlight).then(() => {
        this.myGroup = group;
      }).catch(() => {
        this.$store.commit('showSnackbar', this.$t('common.error'));
      }).finally(() => {
        this.loading = false;
      });
    },
    parseTime(time) {
      let m = Math.floor(time / 60);
      let s = time % 60;
      return m + ':' + s.toString().padStart(2, '0');
    }
  }
};
</script>

<style scoped>
#info {
  color: #336799;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 24px;
  margin-top: 8px;
  padding: 24px;
}

.invitation {
  margin: 24px;
}

.invitation-card {
  border: 3px solid #336799;
  border-radius: 16px;
  height: auto;
  margin: 10px;
  width: calc(100% - 20px);
}

.invitation-card-title {
  color: #336799;
  font-size: 26px;
  font-weight: bold;
  margin-left: 10px;
}

.invitation-card-info-1 {
  padding: 0 4px;
  min-width: 100px;
}

.invitation-card-info-2 {
  padding: 0 4px;
  min-width: 40px;
}
</style>
